var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.notices && _vm.notices.length
    ? _c("Card", [
        _c(
          "ul",
          { staticClass: "c-notices" },
          _vm._l(_vm.notices, function (item) {
            return _c("li", { key: item.index, staticClass: "c-notice" }, [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.$customUrlScheme.page(
                      "standalone",
                      "url=/notice/detail/" + item.id
                    ),
                  },
                },
                [
                  _c("Icon", {
                    attrs: { name: "information", color: "secondary" },
                  }),
                  _c("span", [_vm._v(_vm._s(item.title))]),
                ],
                1
              ),
            ])
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }