<template>
  <Card v-if="notices && notices.length">
    <ul class="c-notices">
      <li class="c-notice" v-for="item in notices" :key="item.index">
        <a
          :href="
            $customUrlScheme.page('standalone', 'url=/notice/detail/' + item.id)
          ">
          <Icon name="information" color="secondary" />
          <span>{{ item.title }}</span>
        </a>
      </li>
    </ul>
  </Card>
</template>

<script>
import { SystemDialogMessage } from '@/constants/enums';

export default {
  data: () => ({
    notices: undefined
  }),

  created() {
    this.fetchList();
  },

  methods: {
    async fetchList() {
      try {
        this.$_loading_start();

        const { data } = await this.$repositories('contentTopNotice').get();
        this.notices = data.filter((item) => item['is_published'] === 1);
      } catch {
        window.location.href = this.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        this.$_loading_stop();
      }
    }
  }
};
</script>
